import http, { api } from '../axios'

const getCallScenarios = async (page = 1, limit = 10) => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/list?page=${page}&limit=${limit}`
  )
  return res.data
}
const getCallCampaignScenarios = async (page = 1, limit = 10) => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/list?page=${page}&limit=${limit}`
  )
  return res.data
}
const getCallFlowTypes = async () => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-flow/call-campaign`
  )
  return res.data
}

const getCallFlowType = async id => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-flow/${id}/brief`
  )
  return res.data
}

const getCallScenarioParams = async id => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/${id}/variables`
  )
  return res.data
}

const initiateCall = async (scenarioId, data) => {
  let response
  try {
    response = await http.post(
      `${process.env.VUE_APP_NEW_API}call-campaign/scenario/${scenarioId}/form/initiate`,
      data
    )

    return {
      hasError: false,
      error: ''
    }
  } catch (error) {
    return {
      hasError: true,
      errors: error.response.data.message || ''
    }
  }
}

const initiateCallFile = async (scenarioId, data) => {
  let formData = new FormData()
  for (let key in data) {
    formData.append(key, data[key])
  }

  await api.post(
    `call-campaign/scenario/${scenarioId}/file/initiate`,
    formData,
    {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

const uploadSound = async sound => {
  let data = new FormData()
  data.append('file', sound)

  const res = await http.post(
    `${process.env.VUE_APP_NEW_API}sound/upload`,
    data
  )

  return res.data
}
const createCallFlow = async data => {
  const res = await http.post(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/create`,
    data
  )

  return res.data
}
const getCallFiles = async (page = 1, limit = 10) => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/calls-files?page=${page}&limit=${limit}`
  )

  return res.data
}
const getCorruptedRows = async (id, page = 1, limit = 10) => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/calls-files/${id}/corrupted-rows?page=${page}&limit=${limit}`
  )
  return res.data
}

const ignoreCorruptedRow = async id => {
  const res = await http.patch(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/call-file/${id}/ignore-corrupted`
  )
  return res.data
}

const cancelCallFile = async id => {
  const res = await http.patch(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/call-file/${id}/cancel`
  )
  return res.data
}

const ignoreAllCorrupted = async id => {
  const res = await http.patch(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/call-file/${id}/ignore-corrupted`
  )
  return res.data
}
const fixCorruptedRow = async (corruptedRowId, row) => {
  const res = await http.post(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/corrupted-row/${corruptedRowId}/fix`,
    row
  )
  return res.data
}

const getFormCallsDetails = async (page = 1, limit = 10) => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/form-calls-details?page=${page}&limit=${limit}`
  )

  return res.data
}
const getScenarioTemplate = async flowId => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/${flowId}/download-template`
  )

  return res.data
}

const pauseCallFile = async callFileId => {
  const res = await http.patch(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/call-file/${callFileId}/pause`
  )
  return res.data
}
const resumeCallFile = async callFileId => {
  const res = await http.patch(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/call-file/${callFileId}/resume`
  )
  return res.data
}

const getCallFlow = async flowId => {
  const res = await http.get(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/${flowId}`
  )

  return res.data
}
const editCallFlow = async (flowId, data) => {
  const res = await http.patch(
    `${process.env.VUE_APP_NEW_API}call-campaign/scenario/update/${flowId}`,
    data
  )

  return res.data
}

const callCampaignService = {
  getCallScenarios,
  getCallCampaignScenarios,
  getCallFlowTypes,
  getCallFlowType,
  getCallScenarioParams,
  initiateCall,
  initiateCallFile,
  uploadSound,
  createCallFlow,
  getCallFiles,
  getCorruptedRows,
  ignoreCorruptedRow,
  cancelCallFile,
  getFormCallsDetails,
  fixCorruptedRow,
  ignoreAllCorrupted,
  getScenarioTemplate,
  pauseCallFile,
  resumeCallFile,
  getCallFlow,
  editCallFlow
}

export default callCampaignService
