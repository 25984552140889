<template>
  <div class="d-flex actions-cell">
    <button
      class="btn px-3 btn-sm btn-primary mx-2"
      :disabled="row.progress !== 100"
      @click="$emit('downloadExcelFile', row.id)"
    >
      {{ $t('call-campaign.calls.excel.table.download') }}
    </button>
    <router-link
      :to="{
        name: 'call-campaign-excel-call-file',
        params: { id: row.id }
      }"
    >
      <button class="btn px-3 btn-sm btn-primary" v-if="status === 'failed'">
        {{ $t('call-campaign.calls.excel.table.show-corrupted') }}
      </button>
    </router-link>

    <span v-if="row.progress < 100 && ['ready', 'paused'].includes(status)">
      <span v-if="isLoading">
        <half-circle-spinner
          slot="loading"
          :animation-duration="1500"
          :size="25"
          color="#74b9ff"
          class="mx-auto"
        />
      </span>
      <div class="d-flex" v-else>
        <button
          v-if="row.pauseable && status === 'paused'"
          class="btn btn-text shadow-none p-2"
          @click="progress()"
        >
          <i class="fa fa-play" aria-hidden="true"></i>
        </button>
        <button
          v-else-if="row.pauseable && status === 'ready'"
          class="btn btn-text shadow-none p-2"
          @click="pause()"
        >
          <i class="fa fa-pause" aria-hidden="true"></i>
        </button>
      </div>
    </span>
  </div>
</template>

<script>
import callCampaignService from '../../../services/callCampaign.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      status: 'ready'
    }
  },
  watch: {
    row() {
      this.status = this.row.status
    }
  },
  mounted() {
    this.status = this.row.status
  },
  methods: {
    async progress() {
      this.isLoading = true
      try {
        const res = await callCampaignService.resumeCallFile(this.row.id)
        this.status = 'ready'
        this.$emit('changeStatus', 'ready')
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async pause() {
      this.isLoading = true
      try {
        const res = await callCampaignService.pauseCallFile(this.row.id)
        this.status = 'paused'
        this.$emit('changeStatus', 'paused')
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
